
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class LandingPage extends Vue {
    private privacy_policy = [
        {title: "شرایط و قوانین استفاده از سرویس ها و خدمات لاکی لایو", description: "ورود کاربران به وبسایت لاکی لایو و ایجاد یا شرکت در قرعه کشی و یا استفاده از دیگر خدمات ارائه شده توسط لاکی لایو، به معنای آگاه بودن و پذیرفتن شرایط، قوانین استفاده از خدمات می باشد."},
        {title: "قوانین عمومی", description: 'توجه داشته باشید که لاکی لایو بر اساس اصول قانون تجارت الکترونیک و قانون حمایت از حقوق مصرف کننده عمل می کند، کاربر نیز متعهد به رعایت قوانین می باشد.'},
        {title: "تعریف  کاربر", description: `کاربر به شخصی گفته می شود که از لاکی لایو برای برگزاری قرعه‌کشی و دورهمی استفاده میکند، یا کسی که در این قرعه‌کشی ها در داخل لاکی لایو شرکت میکند.
        کاربر مستلزم است برای ایجاد قرعه کشی یا شرکت در قسمت ارتباطات، حساب کاربری ایجاد کند. کاربر با ایجاد این حساب تایید می کند که بالای ۱۸ سال سن دارد و شماره همراه وارد شده برای حساب متعلق به او می باشد.
        کاربر متعهد می شود در محیط لاکی لایو از هرگونه رفتار خصمانه، زننده، پرخاشگرانه، توهین آمیز، نفرت آمیز و خلاف عرف دوری کنند. و درصورت عدم رعایت ،با تشخیص سیستم لاکی لایو یا مراجع مرتبط، حق بستن حساب او را دارند.
        `},
        {title: "تعریف مدیریت", description: `توجه داشته باشید که لاکی لایو یک پلتفرم بدون ادمین می باشد، تمامی کارهای مربوط به محیط لاکی لایو به صورت خودکار انجام می شود. این امر به منظور دخالت حداقلی انسانی در روند کارها صورت گرفته است.
        به همین منظور لاکی لایو دارای توانایی شناخت شرایط ویژه می باشد. برای مثال درصورتی که یک قرعه کشی از پست خصوصی ایجاد شود. لاکی لایو تشخیص داده و قرعه کشی را متوقف می کند. یا اگر قرعه‌کشی با مشکل رو به رو شود، لاکی لایو تا چندین بار با فاصله زمانی تلاش میکند که قرعه کشی انجام شود.
        تیم پشتیبانی نیز خارج از محیط لاکی لایو و در بستر تـایه قرار دارد. 
        ممکن است تغییراتی در بین نسخه های مختلف به وجود آید، در صورتی که مدیریت انسانی به سیستم اضافه شود، حتما این متن تغییر داده خواهد شد.
        تیم توسعه لاکی لایو دائما در صدد است با بهبود هر چه بیشتر، احتمال خطا در قرعه کشی ها را به صفر برساند، کما اینکه تا اکنون با مشکلی مواجه نشده ایم. 
        اما در صورتی که به دلایل مختلف ،مانند تغییر در پلتفرم اینستاگرام، مشکلی در روند قرعه کشی بروز دهد، ضمن انجام تمام تلاش تیم پشتیبانی برای بازگرداندن قرعه‌کشی، امکان بازگشت وجه به دلیل عدم وجود یک پنل مدیریت وجود ندارد. (این مورد نیز ممکن است در آینده بصورت خودکار توسط لاکی‌لایو اجرا شود.)
        `},
        {title: "سیاست رعایت حریم شخصی", description: `لاکی لایو به اطلاعات خصوصی اشخاصی که از خدمات سایت استفاده می‌کنند، احترام گذاشته و از آن محافظت میکند. 
        لاکی لایو متعهد می‌شود در حد توان از حریم شخصی شما دفاع کند. در این راستا با بروزرسانی فناوری های استفاده شده، بهبود قوانین حاکم بر سایت سعی می کند از حقوق شما دفاع کند.
        تمام مطالب و محتوای در دسترس از طریق خدمات لاکی لایو، مانند متن، گرافیک، آرم، آیکون، طرح، کد، ویدئو و تصاویر و کلیه محتوای تولید شده توسط سایت لاکی لایو جزئی از دارایی های معنوی ما محسوب می‌شود و حق استفاده و نشر تمامی مطالب موجود و در دسترس در انحصار لاکی لایو است و هرگونه استفاده بدون کسب مجوز کتبی، حق پیگرد قانونی را برا ما محفوظ می‌دارد.`},
        {title: 'ایجاد، پردازش و برگزاری قرعه‌کشی', description: `همانطور که پیش‌تر بیان شد، لاکی لایو بدون مدیریت انسانی فعالیت می‌کند.
        1. هرگونه ثبت سفارش منوط به ایجاد حساب کاربری می‌باشد.
        2. پذیرش یا عدم پذیرش قرعه‌کشی تنها مرتبط با قوانین حاکم بر لاکی لایو می‌باشد. و در صورتی که لاکی لایو به دلایلی مانند، تعداد زیاد قرعه‌کشی، پذیرش را انجام ندهد می توانید مجددا تلاش کنید.
        3. لاکی لایو زیرمجموعه ای از بستر تایه می باشد، کدهای تخفیف تعریف شده در تـایه قابل استفاده در لاکی لایو می‌باشند.
        4. در صورت بروز هرگونه مشکل در قرعه‌کشی، حق اطلاع از دلیل مشکل، و تلاش تیم پشتیبانی برای رفع آن برای مشتری محفوظ است.
        5. کاربر باید هنگام ایجاد قرعه کشی اطلاعات فرم را بررسی کنند، بدیهی است در صورت صحیح نبودن اطلاعات لاکی لایو امکان برگزاری را نخواهد داشت.
        6. اجرای قرعه‌کشی منوط به پرداخت وجه تعیین شده می باشد.
        7. قراردادن محصولات غیر‌مجاز، غیر استاندارد، خلاف عرف، خلاف قانون، خلاف شرع، زننده و نامناسب برای عموم در پلتفرم لاکی لایو غیر مجاز می باشد.
        8. با توجه به متغیر بودن شبکه های اجتماعی، در صورت تغییر در این شبکه ها تیم پشتیبانی لاکی لایو سعی میکند در اسرع وقت تغییرات را در لاکی لایو اعمال کند.
        9. مسئولیت تمامی پیام ها و رفتار ها در قرعه‌کشی و بخش ارتباط مربوط به خود آن اشخاص می باشد.`},
    ];
}
